<template>
  <div class="scoreMall">
    <div class="scoreTitle">
      积分商城
      <img src="../assets/back.png" @click="goBack()" alt="" />
    </div>
    <div class="scoreMallList">
      <div
        class="scoreItem"
        @click="goDetail(item)"
        v-for="item in scoreData"
        :key="item.id"
      >
        <div class="scoreItem-top">
          <img :src="item.image" class="scoreItem-topImg" alt="" />
        </div>
        <div class="scoreItem-bot">
          <div class="scoreItem-title">
            <div>{{ item.name }}</div>
          </div>
          <div class="scoreItem-author">
            <div class="left">
              <img :src="item.author_image" alt="" />
              <div>{{ item.author_name }}</div>
            </div>
            <div class="right">{{ item.price }}积分</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getScore } from "../api/index.js";
export default {
  setup() {},
  data() {
    return {
      scoreData: [],
      page: 1,
    };
  },
  mounted() {
    this.getScoreData();
  },
  methods: {
    goDetail(item) {
      this.$router.push({
        path: "/detail",
        query: {
          id: item.id,
        },
      });
    },
    getScoreData() {
      let params = {
        page: this.page,
        pagesize: 10,
      };
      getScore(params).then((res) => {
        this.scoreData = res.data.data;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.scoreMall {
  .scoreTitle {
    height: 40px;
    background: #ffffff;
    position: relative;
    font-size: 15px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      width: 16px;
      margin: auto;
    }
  }
  .scoreMallList {
    padding: 15px;

    .scoreItem {
      width: 100%;
      overflow: hidden;
      background: #ffffff;
      box-shadow: 0px 0px 7px 0px #eef1fd;
      border-radius: 10px;
      margin-bottom: 15px;
      .scoreItem-top {
        width: 100%;
        .scoreItem-topImg {
          width: 100%;
        }
      }
      .scoreItem-bot {
        padding: 10px 15px 22px;
        .scoreItem-title {
          div {
            font-size: 17px;
            color: #333333;
          }
        }
        .scoreItem-author {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 9px;
          .left {
            display: flex;
            align-items: center;
            img {
              width: 18px;
            }
            div {
              font-size: 12px;
              color: #999999;
              margin-left: 5px;
            }
          }
          .right {
            font-size: 16px;
            color: #333;
          }
        }
      }
    }
  }
}
</style>